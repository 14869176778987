
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import ApiService from "@/core/services/ApiService";
import { Dropbox, Error, files } from 'dropbox';
import axios from 'axios';
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import JwtService from "@/core/services/JwtService";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";
import { useRouter } from "vue-router";

//const dbx = new Dropbox({ accessToken: process.env.VUE_APP_DBX_TOKEN });
let dbx;
const STATUS_INITIAL = 0, STATUS_UPLOADING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;
const router = useRouter();

export default defineComponent({
  name: "widgets-tables",
  
  data() {
    return {
      uploadTo: "new",
      projectsNew: {},
      projectsArchive: {},
      project: {
        id: 0,
        name: '',
        path: '',
      },
      uploadNotification: 0,
      uploadStartDate: '',
      selectedProject: '',
      uploadPath: '',
      files: [],

      uploadedFiles: '',
      uploadError: null,
      currentStatus: 0,
      uploadFieldName: 'photos',

      noProject: true,
      noProjectMessage: 'Choose a Project to assign the video files',

      //uploadPercentage: {},
      uploadDone: false,
      currentUser: {
        id: 0,
        name: "",
        role: 0,
      },

      fileUploadList: [{}],
      showUploadList: false,
      showUploadButton: false,
    };
  },

  watch: {

    fileUploadList: {
      handler(val, oldVal) {
        /* remove blank entry */
        this.fileUploadList.forEach( (value, index) => {
          if (!this.fileUploadList[index]['name']) {
            this.fileUploadList.splice(index, 1);
          }
        });

        /*check if all are uploaded*/
        let allUploaded = true;
        let files = '';
        this.fileUploadList.forEach( (value, index) => {
          if (this.fileUploadList[index]['status'] == 3 || 
              this.fileUploadList[index]['status'] == 4 ||
              this.fileUploadList[index]['status'] == 5 ||
              this.fileUploadList[index]['status'] == 500 ) {
            
            if (this.fileUploadList[index]['status'] == 3) {
              if (this.fileUploadList[index]['file'] !== '') {
                files = files + this.fileUploadList[index]['file'].name + ', ';
              }
            }
          }else{ 
            allUploaded = false;
          }
        });

        if (allUploaded && files !== '') {
          this.uploadedFiles = files;
          this.fileUploadList.forEach( (value, index) => {
            this.fileUploadList[index]['file'] = '';
          });
          this.notifyUploadEnd();
          this.notifySuccess();
          this.reset();
        }

        if (this.fileUploadList.length) {
          this.showUploadList = true;
          if (this.isSaving) {
            this.showUploadButton = false;
          }else{
            this.showUploadButton = true;
          }
        }else{
          this.showUploadList = false;
          this.showUploadButton = false;
        }
      },
      deep: true
    },

    uploadTo: function() {
      this.uploadPath = '';
      this.noProject = true;
      this.project.id = 0;
      this.project.name = '';
      this.selectedProject = '';
    },
  },

  setup() {

    onMounted(() => {
      setCurrentPageTitle("Upload Video Files");
      MenuComponent.reinitialization();
    });

    const prettyBytes = (num) => {

      if (typeof num !== 'number' || isNaN(num)) {
        throw new TypeError('Expected a number');
      }

      var exponent;
      var unit;
      var neg = num < 0;
      var units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

      if (neg) {
        num = -num;
      }

      if (num < 1) {
        return (neg ? '-' : '') + num + ' B';
      }

      exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), units.length - 1);
      num = parseInt((num / Math.pow(1000, exponent) ).toFixed(2)) * 1;
      unit = units[exponent];

      return (neg ? '-' : '') + num + ' ' + unit;

    }

    const alertFinish = (text, icon) => {
      Swal.fire({
        text: text,
        icon: icon,
        buttonsStyling: false,
        confirmButtonText: "Continue",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
        },
      });
    }
    
    const buttonUploadFinish = async (pid, event) => {
      event.preventDefault();

      const payload = {
				data: {
          item_id: pid,
				}
			};
      let swalText = '';
      let swalIcon = "success";
      ApiService.setHeader();
			await ApiService.post("monday/notify-upload/finish", payload)
				.then(async ({ data }) => {
          if (data.code == 200) {
              swalText = 'Upload status is now completed'
          }else{
              swalText = 'Unable to change the status'
              swalIcon = "error";
          }
          alertFinish(swalText, swalIcon )
				})
				.catch(({ response }) => {
          swalText = 'Unable to change the status'
          swalIcon = "error";
          alertFinish(swalText, swalIcon )
				});
    }

    return {
      prettyBytes,
      buttonUploadFinish,
    }

  },

  computed: {
    isInitial(): boolean {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving(): boolean {
      return this.currentStatus === STATUS_UPLOADING;
    },
    isSuccess(): boolean {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed(): boolean {
      return this.currentStatus === STATUS_FAILED;
    }
  },

  methods: {
    async init() {
      const token = await this.getDbxToken();
      dbx = new Dropbox({ accessToken: token });
    },

    async getProjectsNew() {
      ApiService.setHeader();
      this.projectsNew = await ApiService.get("monday/board/items/new")
        .then(({ data }) => {
          return data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    async getProjectsArchive() {
      ApiService.setHeader();
      this.projectsArchive = await ApiService.get("monday/board/items/archive")
        .then(({ data }) => {
          return data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    async getDbxToken() {
      ApiService.setHeader();
      return await ApiService.get("dropbox/get-token")
        .then(({ data }) => {
          return data.token;
        })
        .catch(({ response }) => {
          Swal.fire({
            text: "Unable to get token",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          })
        });
    },
    
    onSelectProject(source) {
      const project_id = this.selectedProject;
      
      this.uploadPath = '';
      this.noProject = true;

      if (source == 'new') {
        const projects = this.projectsNew['data'];
        let project = projects.filter(function(elem) {
          if (elem.id == project_id) {
            return elem;
          }
        });
        project = project[0];
        const proj = project.column_values.filter(function(elem) {
          if (elem.id == 'text6') {
            return elem;
          }
        });
        this.uploadPath = proj[0].value;
        this.project.path = this.uploadPath;
        this.project.name = project.name;
        this.project.id = project.id;


      }else{
        const projects = this.projectsArchive['data'];
        let project = projects.filter(function(elem) {
          if (elem.id == project_id) {
            return elem;
          }
        });
        project = project[0];
        const proj = project.column_values.filter(function(elem) {
          if (elem.id == 'text6') {
            return elem;
          }
        });
        this.uploadPath = proj[0].value;
        this.project.path = this.uploadPath;
        this.project.name = project.name;
        this.project.id = project.id;
      }
      
      if (this.uploadPath === '' || this.uploadPath === null) {
        this.noProject = true;
        this.noProjectMessage = 'The selected Project do not have an upload path, please select another project<br>or contact the admin'
      }else{
        this.noProject = false;
      }

    },

    notifySuccess() {
      Swal.fire({
        text: "All files are uploaded",
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Continue",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
        },
      }).then(function () {
        //console.log('success');
      });
    },

    notifyFailed() {
      Swal.fire({
        text: "Upload failed, please try again or contact admin.",
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: "Ok",
        customClass: {
          confirmButton: "btn btn-primary",
        },
      }).then(() => {
        this.reset();
      });
    },

    reset() {
        // reset form to initial state
        this.currentStatus = STATUS_INITIAL;
        this.uploadedFiles = '';
        this.uploadError = null;
        //this.uploadPercentage = [];
    },

    async notifyUploadStart() {
      const payload = {
				data: {
					item_id: this.project.id,
					item_name: this.project.name,
          path: this.project.path
				}
			};
			ApiService.setHeader();
			const resp = await ApiService.post("monday/notify-upload/start", payload)
				.then(async ({ data }) => {
					this.uploadNotification = data.id;
          this.uploadStartDate = data.start_datetime;
				})
				.catch(({ response }) => {
					console.log(response);
				});
    },

    async notifyUploadEnd() {
      const payload = {
				data: {
          item_id: this.project.id,
					item_name: this.project.name,
          path: this.project.path,
					upload_item_id: this.uploadNotification,
          item_files: this.uploadedFiles,
          start_datetime: this.uploadStartDate
				}
			};
			ApiService.setHeader();
			const resp = await ApiService.post("monday/notify-upload/end", payload)
				.then(async ({ data }) => {
          const resp = data;
				})
				.catch(({ response }) => {
					console.log(response);
				});
    },
    
    filesChange(fieldName, fileList) {
      // handle file changes
      /* disable upload */

      this.verifyAuth();

      this.files = fileList;

      if (!fileList.length) return;

      // append the files to FormData
      Array
        .from(Array(fileList.length).keys())
        .map(async x => {
          //this.uploadPercentage[x] = { variant: x, value: 0, file_name: fileList[x].name, fileSize: 0, uploadedSize: 0};

          const fileName = fileList[x].name.split('.');
          let fileExt = fileName.slice(-1)[0];
          fileExt = fileExt.toLowerCase().trim();

          if (
              fileList[x].type == 'video/mp4' ||
              fileList[x].type == 'video/mpeg' ||
              fileList[x].type == 'video/x-msvideo' ||
              fileList[x].type == 'video/ogg' ||
              fileList[x].type == 'video/mp2t' ||
              fileList[x].type == 'video/webm' ||
              fileList[x].type == 'video/AV1' ||
              fileList[x].type == 'video/H261' ||
              fileList[x].type == 'video/H263' ||
              fileList[x].type == 'video/H263-1998' ||
              fileList[x].type == 'video/H263-2000' ||
              fileList[x].type == 'video/H264' ||
              fileList[x].type == 'video/H264-RCDO' ||
              fileList[x].type == 'video/H264-SVC' ||
              fileList[x].type == 'video/H265' ||
              fileList[x].type == 'video/iso.segment' ||
              fileList[x].type == 'video/JPEG' ||
              fileList[x].type == 'video/jpeg2000' ||
              fileList[x].type == 'video/MPV' ||
              fileList[x].type == 'video/mpeg4-generic' ||
              fileList[x].type == 'video/quicktime' ||
              fileList[x].type == 'audio/mpeg' ||
              fileList[x].type == 'audio/ogg' ||
              fileList[x].type == 'audio/aac' ||
              fileList[x].type == 'audio/wav' ||
              fileList[x].type == 'audio/webm' ||
              fileList[x].type == 'image/bmp' ||
              fileList[x].type == 'image/gif' ||
              fileList[x].type == 'image/jpeg' ||
              fileList[x].type == 'image/png' ||
              fileList[x].type == 'image/tiff' ||
              fileList[x].type == 'image/webp' ||
              fileList[x].type == 'application/msword' ||
              fileList[x].type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
              fileList[x].type == 'application/ogg' ||
              fileList[x].type == 'application/pdf' ||
              fileList[x].type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
              fileList[x].type == 'application/xml' ||
              fileList[x].type == 'application/vnd.ms-powerpoint' ||
              fileList[x].type == 'application/vnd.ms-powerpoint' ||
              fileList[x].type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
              fileList[x].type == 'application/vnd.openxmlformats-officedocument.presentationml.template' ||
              fileList[x].type == 'application/vnd.openxmlformats-officedocument.presentationml.slideshow' ||
              fileList[x].type == 'application/vnd.ms-powerpoint.addin.macroEnabled.12' ||
              fileList[x].type == 'application/vnd.ms-powerpoint.presentation.macroEnabled.12' ||
              fileList[x].type == 'application/vnd.ms-powerpoint.template.macroEnabled.12' ||
              fileList[x].type == 'application/vnd.ms-powerpoint.slideshow.macroEnabled.12' || 
              fileList[x].type == 'application/mxf' ||
              fileList[x].type == 'model/vnd.mts' ||
              fileExt == 'mxf' ||
              fileExt == 'mts' ||
              fileExt == 'mt2s' ||
              fileExt == 'm2ts' ||
              fileList[x].type == 'audio/x-ms-wma' ||
              fileList[x].type == 'video/x-ms-wmv'
          ) {

            this.fileUploadList.push({
              file: fileList[x],
              name: fileList[x].name,
              details: fileList[x].type + ', ' + this.prettyBytes(fileList[x].size),
              status: 0,
              progress: 0,
              color: 'primary',
            });
          }
        });
    },

    cancelUplaod(idx, event) {
      event.preventDefault();
      this.fileUploadList[idx]['status'] = 4;
    },

    removeFile(idx, event) {
      event.preventDefault();
      this.fileUploadList.splice(idx, 1);
    },

    async startUploadFile(event){
      event.preventDefault();
      /* disable upload  */
      this.notifyUploadStart();

      this.currentStatus = STATUS_UPLOADING;
      
      this.fileUploadList.forEach( (value, index) => {
        if (this.fileUploadList[index]['file'] !== '') {
          this.fileUploadList[index]['status'] = 1  ;
        }
      });
      
      let i = 0;
      for (const element of this.fileUploadList) {
        if (this.fileUploadList[i]['file'] !== '' || this.fileUploadList[i]['status'] !== 3) {
          this.fileUploadList[i]['status'] = 2;
          
          const resp = await this.uploadFile(this.fileUploadList[i]['file'], i);
          if (resp) {
            this.fileUploadList[i]['status'] = 3;
          }
        }
        i++;
      }
    },

    async uploadFile(file, idx) {

      const UPLOAD_FILE_SIZE_LIMIT = 150 * 1024 * 1024;
      let dbxPath = this.uploadPath.replace(/[^a-z0-9-_]/i, '');
      dbxPath = this.uploadPath.replace(/["]+/g, '');

      const dbxToken = await this.getDbxToken();
      
      if (file.size < UPLOAD_FILE_SIZE_LIMIT) { // File is smaller than 150 Mb - use filesUpload API

        //let percentCompleted = {};
        
        const config = {
          headers: {
              'Content-Type': 'application/octet-stream',
              'Authorization': 'Bearer ' + dbxToken,
              'Dropbox-API-Arg': '{"path": "'+dbxPath +'/' + file.name+'","mode": "add","autorename": true,"mute": false, "strict_conflict": false}',
          }, 
          onUploadProgress: ( progressEvent ) => {
            this.fileUploadList[idx]['progress'] = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            //this.fileUploadList[idx]['progressSize'] = ((progressEvent.loaded / 1000)/100) + 'MB';
            console.log('uploading ' + idx + ' = ' + this.fileUploadList[idx]['progress'] );
          }
        }

        await axios.post( 'https://content.dropboxapi.com/2/files/upload', file, config)
          .then( response => {
            console.log('SUCCESS!! ' + idx);
            return true;
          })
          .catch(error => {
            console.error(error);
            console.log(this.fileUploadList[idx]);
            this.notifyFailed();
          });

        return true;

      } else { // File is bigger than 150 Mb - use filesUploadSession* API

        const maxBlob = 8 * 1000 * 1000; // 8Mb - Dropbox JavaScript API suggested max file / chunk size
        
        let workItems = [] as Array<any>;     
      
        let offset = 0;
        
        while (offset < file.size) {
          var chunkSize = Math.min(maxBlob, file.size - offset);
          const uploadStatus = await workItems.push( file.slice( offset, offset + chunkSize) );
          offset += chunkSize;
        }

/*        const loop = async (workItems) => {
          const chunks = Math.ceil(file.size / maxBlob);
          for (let ctr = 0; ctr < chunks; ctr++) {
            var chunkSize = Math.min(maxBlob, file.size - offset);
            const uploadStatus = await workItems.push( file.slice( offset, offset + chunkSize) );
            offset += chunkSize;
          }
        };

        await loop(workItems);
*/

        const task = workItems.reduce( (acc, blob, index, items) => {

          //this.uploadPercentage[idx].uploadedSize = index * maxBlob;
          //console.log(this.uploadPercentage[idx].uploadedSize);
          if (index == 0) {
            // Starting multipart upload of file
            return acc.then( async () => {
              //this.uploadPercentage[idx].value = Math.round(( (index * maxBlob) * 100) / file.size);
              //console.log('uploading ...' + this.uploadPercentage[idx].value);

              const res = await dbx.filesUploadSessionStart({ close: false, contents: blob})
                        .then(response => response.result.session_id)

              this.fileUploadList[idx]['progress'] = Math.round(( (index * maxBlob) * 100) / file.size);
              //this.fileUploadList[idx]['progressSize'] = (((index * maxBlob) / 1000) / 1000) + 'MB';
              console.log('uploading ' + idx + ' = ' + (index * maxBlob) );

              return res;

            });         


          /* Upload large file */
          } else if (index < items.length-1) {  
            // Append part to the upload session
            return acc.then(async (sessionId) => {
              var cursor = { session_id: sessionId, offset: index * maxBlob };

              if( this.fileUploadList[idx]['status'] == 2) {
                const res = await dbx.filesUploadSessionAppendV2({ cursor: cursor, close: false, contents: blob }).then(() => sessionId); 
                this.fileUploadList[idx]['progress'] = Math.round(( (index * maxBlob) * 100) / file.size);
                //this.fileUploadList[idx]['progressSize'] = (((index * maxBlob) / 1000) / 1000) + 'MB';
                console.log('uploading ' + idx + ' = ' + (index * maxBlob) );
                return res;
              }else{
                return false;
              }
            });

          } else {
            // Last chunk of data, close session
            return acc.then( async  (sessionId) => {
                var cursor = { session_id: sessionId, offset: file.size - blob.size };
                var commit = { path: dbxPath +'/' + file.name,  autorename: true, mute: false };              

                if( this.fileUploadList[idx]['status'] == 2) {
                  const res = await dbx.filesUploadSessionFinish({ cursor: cursor, commit: commit, contents: blob });   
                  this.fileUploadList[idx]['progress'] = Math.round(( (index * maxBlob) * 100) / file.size);  
                  //this.fileUploadList[idx]['progressSize'] = (((index * maxBlob) / 1000) / 1000) + 'MB';
                  console.log('uploading ' + idx + ' = ' + (index * maxBlob) );
                  return res;
                }else{
                  return false;
                }
            });

          }

        }, Promise.resolve());
        
        await task.then( result => {
          if (result) {
            this.fileUploadList[idx]['progress'] = 100;
            this.fileUploadList[idx]['status'] = 3;
            console.log('SUCCESS!! ' + idx);
          }else{
            this.fileUploadList[idx]['progress'] = 0;
            this.fileUploadList[idx]['status'] = 5;
            console.log('CANCELLED!! ' + idx);
          }

        }).catch( (error) => {
          //console.error(error);
          this.fileUploadList[idx]['progress'] = 0;
          this.fileUploadList[idx]['status'] = 500;
          //this.notifyFailed();
        });
        
      }
    },

    verifyAuth() {
      const token = JwtService.getToken();
      if (token == "" || token === null) {
        router.push({ name: "sign-in" });
      }
      store
        .dispatch(Actions.VERIFY_AUTH)
        .then(() => {
          this.currentUser = store.getters.currentUser.user;
        })
        .catch(() => {
          router.push({ name: "sign-in" });
        });
    },

  },

  mounted() {
    this.init();
    this.getProjectsNew();
    this.getProjectsArchive();
    this.reset();
  },
});
